<template>
  <div class="alt-comp-wrapper">
    <v-select-alt
      :rules="[allRules.required]"
      label="Role"
      id="userRole"
      placeholder="Role"
      :value="value"
      @input="emitRole"
      :items="roles"
      dense
      filled
      item-text="name"
      item-value="id"
      :loading="isRolesLoading"
      :readonly="readonly"
      style="flex: 1"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <div
          :key="item + '_roleSelector'"
          class="d-flex align-center font-weight-medium"
          style="font-size: 14px"
        >
          {{ data.item.name }}
        </div>
      </template>
      <template #append-item>
        <div v-intersect="loadMore" />
      </template>
    </v-select-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      target="_blank"
      :to="`/roles/${value}`"
      :disabled="value == null || readonly"
      v-if="allowView"
    >
      <i class="fal fa-external-link"></i>
    </v-btn>
  </div>
</template>

<script>
import rolesAPI from "../services/external-roles-service";

export default {
  components: {},
  props: {
    value: {
      type: [Number, String],
    },
    obj: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      roles: [],
      isRolesLoading: false,
      rolesTimerId: null,
      total: 0,
      mainSearchInFocus: false,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
    };
  },
  methods: {
    emitRole(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.roles.find((elm) => elm.id == id) : null);
    },
    getData() {
      this.isRolesLoading = true;
      rolesAPI
        .query({
          ...this.options,
        })
        .then((resp) => {
          this.addUniqueArr(this.roles, resp.data.items, "id");
          this.total = resp.data.total;
          this.refreshAutocomplete();
          this.isRolesLoading = false;
        })
        .catch(() => {
          this.isRolesLoading = false;
        });
    },

    getDataDebounced() {
      if (this.rolesTimerId == null) {
        this.rolesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.rolesTimerId);
      // delay new call 400ms
      this.rolesTimerId = setTimeout(() => {
        this.getData();
      }, 500);
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.roles.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.roles.findIndex((elm) => elm.id == this.obj.id) == -1 || this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    addAndEmit(item) {
      this.addUniqueArr(this.roles, [item], "id", true);
      this.emitRole(item.id);
    },
    loadMore(undefine, _, isIntersecting) {
      if (isIntersecting && this.total > this.roles.length && !this.isRolesLoading) {
        this.options.page++;
      }
    },
    fetchObj() {
      rolesAPI.getRoleById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  created() {
    this.getDataDebounced();
  },
  watch: {
    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
    obj() {
      this.refreshAutocomplete();
    },
  },
};
</script>
